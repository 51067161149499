<template lang="pug">
  v-app-bar(
    app
    fixed
    dense
    elevate-on-scroll
    style="opacity: 0.95;"
    color="#E1F5FE"
  )
    v-btn(
      small
      text
      color="primary"
      @click="drawerExpand = !drawerExpand"
    )
      v-icon(
        :class="drawerExpand || 'cstm-rotate--90'"
      ) mdi-menu
    v-spacer
    v-btn(
      @click="openLogout"
      fab
      small
      text
    )
      v-icon.primary--text mdi-power
    logout-dialog(ref="logout-dialog")
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'AppBar',
  components: {
    logoutDialog: () => import('@/components/extra/LogoutDialog'),
  },
  data: () => ({
    drawerExpand: true,
  }),
  computed: {
    accessLevel() {
      return this.$store.state.auth.accessLevel
    },
  },
  created() {
    this.drawerExpand = this.$vuetify.breakpoint.width >= 800
  },
  watch: {
    drawerExpand(val) {
      this.$emit('expand-drawer', val)
    },
  },
  methods: {
    ...mapActions({
      clearToken: 'auth/clearToken',
    }),
    openLogout() {
      const { openDialog } = this.$refs['logout-dialog']
      openDialog()
    },
  },
}
</script>
